.container{
  max-width: 120rem;
  padding: 0 2rem;
  margin: 0 auto;
}
.container_privacy{
  max-width: 120rem;
  padding: 0 4rem;
  margin: 0 auto;
}
.container_conditions{
  max-width: 120rem;
  padding: 0 4rem;
  margin: 0 auto;
}