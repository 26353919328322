body{
  font-family: $montserrat-regular;
	background: rgba(11, 0, 26, 1);
	overflow: visible;
	font-size: 1.6rem;
	color: rgba(255, 255, 255, 1);

	&.no-scroll{
		overflow: hidden;
	}
}

.wrapper{
  overflow: hidden;
}

.title{
	font-size: 5rem;
	line-height: 100%;
	font-family: $montserrat-medium;
}

.text{
	font-size: 1.4rem;
  line-height: 2.24rem;
}