.btn_to_top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 100%;
  background-color: rgba(11, 0, 26, 1);
  border-radius: 50%;
  border: 0.2rem solid rgb(72, 60, 8);
  color: rgba(255, 255, 255, 1);
  opacity: 0;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity .5s, transform .3s;
  z-index: 2;
  body.pc &:focus,
  body.mobile &:active,
  body.pc &:hover {
    background-color: #fff;
    color: #000;
  }
  body.pc &:focus{
    transform: scale(1.05);
  }
  &.visible{
    opacity: 1;
  }
  @include _1024 {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem;
  }
}
.contact_us_dark,
.contact_us_light{
  flex: 0 0 auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: -1%;
  padding: 1rem 1.35rem;
  font-family: $montserrat-medium;
  box-shadow: 0px 0px 3.15px 0px rgba(162, 45, 247, 0.1), 
  0px 0px 6.52px 0px rgba(162, 45, 247, 0.1),
  0px 0px 13px 0px rgba(162, 45, 247, 0.1),
  0px 0px 25.48px 0px rgba(162, 45, 247, 0.1),
  0px 0px 46.85px 0px rgba(162, 45, 247, 0.1),
  0px 0px 19px 9px rgba(162, 45, 247, 0.1);
  border: 1px solid;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  span{
    display: inline-block;
    margin: 0 1rem 0 0;
  }
  img{
    width: 1rem;
    height: 1rem;
  }
}

.contact_us_dark{
  border-color: rgba(185, 35, 255, 1);
  background-color: rgba(30, 0, 68, 1);
  color: rgba(233, 222, 255, 1);
}
.contact_us_light{
  border-color: rgba(185, 35, 255, 1);
  background-color: rgba(233, 222, 255, 1);
  color: rgba(11, 0, 26, 1);
}

button{
  @include button-scale;
}