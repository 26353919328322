.story {
  padding: 4rem 0 2rem;
  &__wrap{
    @include _768{
      display: flex;
      justify-content: space-between;
    }
  }
  &__container {
    background-color: rgba(233, 222, 255, 1);
    color: rgba(11, 0, 26, 1);
    border-radius: 4rem;
    padding: 4rem 2rem;
    @include _968{
      padding: 4rem;
    }
  }

  &__wrap_text {
    margin: 0 0 4rem;
    @include _768{
      width: 50%;
      margin: 0 2rem 0 0;
      max-width: 40.2rem;
    }
  }

  &__title {
    text-transform: uppercase;
    margin: 0 0 8rem;
    @include _968{
      margin: 0 0 23rem;
    }
  }

  &__text {
    margin: 0 0 3.2rem;
  }

  &__img {
    border-radius: 3rem;
    overflow: hidden;
    max-width: 66rem;
    max-height: 52rem;
    @include _768{
      width: 50%;
    }
    @include _968{
      width: 60%;
    }
  }
}