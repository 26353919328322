.footer {
  padding: 2rem 0;
  &__container {
    background-color: rgba(30, 0, 68, 1);
    border-radius: 4rem;
    padding: 4rem;
  }

  &__head{
    margin: 0 0 13rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    max-width: 20.5rem;
    max-height: 2.2rem;
    margin: 0 auto;
    @include scale;
    @include _768{
      margin: 0;
    }
  }

  &__wrap_fitst {
    margin: 0 0 6rem;
    
  }
  &__privacy_footer{
    @include _600{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }

  &__title {
    text-align: center;
    margin: 0 0 4rem;
    @include _768{
      font-size: 10rem;
    }
  }

  &__contact {
    @include _600{
      justify-content: center;
      display: flex;
    }
  }
}

.contact {

  &__adress,
  &__phone{
    font-size: 1.6rem;
    line-height: 2.56rem;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    text-decoration: none;
    @include _600{
      text-align: left;
    }
  }

  &__adress {
    font-family: $montserrat-bold;
    margin: 0 0 4rem;
    @include scale;

    @include _600{
      margin: 0 4rem 0 0;
    }
  }

  &__phone {
    font-family: $montserrat-extraBold;
    @include scale;

    span{
      font-family: $montserrat-regular;
    }
  }
}

.privacy_footer{
  &__wrap{
    margin: 0 0 3.2rem;
    @include _600{
      margin: 0;
    }
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 4rem;
      row-gap: 2rem;
      li{
        a{
          font-size: 1.4rem;
          line-height: 2.24rem;
          color: rgba(233, 222, 255, 1);
          text-decoration: none;

          @include hover-underline;
        }
      }
    }
  }
  &__text{
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.24rem;
    color: rgba(233, 222, 255, 1);
    opacity: .5;
    @include _600{
      text-align: left;
    }
  }
}