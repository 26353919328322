.header {
	position: fixed;
	top: 2rem;
	left: 0;
	width: 100%;
	z-index: 100;
	padding: 2rem;
	transition: background-color .3s, top .3s;
	&.backg{
		top: 0;
		background-color: rgba(11, 0, 26, 1);
	}

	&__contact_btn{
		display: none;
		@include _768{
			display: flex;
		}
	}

	&__container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include _968{
			padding: 0 5.6rem 0;
		}
	}

	&__logo{
		max-width: 14.9rem;
		max-height: 1.6rem;
		margin: 0 2rem 0 0;
		a{
			@include scale;
		}
		@include _1024{
			max-width: 20.5rem;
			max-height: 2.2rem;
		}
	}

}

.header__nav {
	position: relative;
	display: none;
	@include _768{
		display: flex;
		align-items: center;
	}

	a{
		text-transform: uppercase;
		color: rgba(233, 222, 255, 1);
		font-size: 1.4rem;
		transition: opacity .3s;
		line-height: 1.4rem;
		text-decoration: none;
		font-family: $montserrat-medium;
		@include hover-underline;
	}

	ul{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 3.8rem;
		row-gap: 1rem;
		margin: 0 2rem 0 0;
	}
}

.nav{
	&__cloud{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: inherit;
		z-index: -1;
	}
}