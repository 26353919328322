.wrapper_privacy{
  background-color: rgba(246, 246, 246, 1);
}
.conditions {
  padding: 0 0 6rem;
  .container_title {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 4rem;
  }


  &__title {
    padding: 13.7rem 0 6rem;
    background-color: rgba(30, 0, 68, 1);
    border-radius: 0px 0px 2rem 2rem;
    margin: 0 0 6rem;
    @include _968{
      padding: 18.8rem 0 4rem;
    }
    h2{
      font-size: 4rem;
      letter-spacing: .09rem;
      line-height: 100%;
      font-family: $montserrat-medium;
      @include _1024{
        font-size: 8rem;
      }
    }
  }

  &__text {
    text-align: left;
    color: rgba(125, 125, 125, 1);
    margin: 0 0 4rem;
    font-size: 1.6rem;
    line-height: 1.95rem;
  }
}

.list{
  &__title{
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 0 1.6rem;
    color: rgba(0, 0, 0, 1);
    font-family: $montserrat-bold;
  }
  &__block{
    margin: 0 0 4rem;
    &:last-child{
      margin: 0;
    }
    p{
      text-align: left;
      color: rgba(125, 125, 125, 1);
      font-size: 1.6rem;
      line-height: 1.95rem;
    }
    ul{
      li{
        display: flex;
        text-align: left;
        color: rgba(125, 125, 125, 1);
        font-size: 1.8rem;
        line-height: 2.3rem;
        &::before{
          content: '';
          flex: 0 0 auto;
          border-radius: 50%;
          display: block;
          margin: 1rem 1rem 0 .9rem;
          width: .2rem;
          height: .2rem;
          background-color: rgba(125, 125, 125, 1);
        }
      }
    }
  }
}