/* Base */
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ul[class],
ol[class] {
  padding: 0;
  margin: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class],
ul {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, video {
  object-fit: cover;
}

svg, img, video {
  width: 100%;
  height: 100%;
  display: block;
}

iframe {
  width: 100%;
  height: 100%;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select,
a {
  font: inherit;
  appearance: none;
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.navbar-brand:focus,
.nav-link:focus,
.btn-close:focus,
.btn:focus {
  box-shadow: none;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding: 0;
}

button,
a {
  display: block;
}

button {
  border: none;
  background-color: transparent;
}

span {
  display: block;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../../fonts/Montserrat-Bold.woff2") format("woff2"), url("../../fonts/Montserrat-Bold.woff") format("woff"), url("../../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat Extra Bold"), local("Montserrat-Extra-Bold"), url("../../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../../fonts/Montserrat-ExtraBold.woff") format("woff"), url("../../fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../../fonts/Montserrat-Medium.woff2") format("woff2"), url("../../fonts/Montserrat-Medium.woff") format("woff"), url("../../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../../fonts/Montserrat-Regular.woff2") format("woff2"), url("../../fonts/Montserrat-Regular.woff") format("woff"), url("../../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.container {
  max-width: 120rem;
  padding: 0 2rem;
  margin: 0 auto;
}

.container_privacy {
  max-width: 120rem;
  padding: 0 4rem;
  margin: 0 auto;
}

.container_conditions {
  max-width: 120rem;
  padding: 0 4rem;
  margin: 0 auto;
}

body {
  font-family: "Montserrat-Regular", serif;
  background: rgb(11, 0, 26);
  overflow: visible;
  font-size: 1.6rem;
  color: rgb(255, 255, 255);
}
body.no-scroll {
  overflow: hidden;
}

.wrapper {
  overflow: hidden;
}

.title {
  font-size: 5rem;
  line-height: 100%;
  font-family: "Montserrat-Medium", serif;
}

.text {
  font-size: 1.4rem;
  line-height: 2.24rem;
}

/* Blocks */
.btn_to_top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 100%;
  background-color: rgb(11, 0, 26);
  border-radius: 50%;
  border: 0.2rem solid rgb(72, 60, 8);
  color: rgb(255, 255, 255);
  opacity: 0;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity 0.5s, transform 0.3s;
  z-index: 2;
}
body.pc .btn_to_top:focus, body.mobile .btn_to_top:active, body.pc .btn_to_top:hover {
  background-color: #fff;
  color: #000;
}
body.pc .btn_to_top:focus {
  transform: scale(1.05);
}
.btn_to_top.visible {
  opacity: 1;
}
@media (min-width: 1024px) {
  .btn_to_top {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem;
  }
}

.contact_us_dark,
.contact_us_light {
  flex: 0 0 auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: -1%;
  padding: 1rem 1.35rem;
  font-family: "Montserrat-Medium", serif;
  box-shadow: 0px 0px 3.15px 0px rgba(162, 45, 247, 0.1), 0px 0px 6.52px 0px rgba(162, 45, 247, 0.1), 0px 0px 13px 0px rgba(162, 45, 247, 0.1), 0px 0px 25.48px 0px rgba(162, 45, 247, 0.1), 0px 0px 46.85px 0px rgba(162, 45, 247, 0.1), 0px 0px 19px 9px rgba(162, 45, 247, 0.1);
  border: 1px solid;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.contact_us_dark span,
.contact_us_light span {
  display: inline-block;
  margin: 0 1rem 0 0;
}
.contact_us_dark img,
.contact_us_light img {
  width: 1rem;
  height: 1rem;
}

.contact_us_dark {
  border-color: rgb(185, 35, 255);
  background-color: rgb(30, 0, 68);
  color: rgb(233, 222, 255);
}

.contact_us_light {
  border-color: rgb(185, 35, 255);
  background-color: rgb(233, 222, 255);
  color: rgb(11, 0, 26);
}

button {
  display: block;
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
}
body.pc button:focus, body.pc button:hover, body.mobile button:active {
  transform: scale(1.1);
}
body.pc button:focus {
  border: 0.2rem solid black;
}
button:active {
  opacity: 0.5;
}

.footer {
  padding: 2rem 0;
}
.footer__container {
  background-color: rgb(30, 0, 68);
  border-radius: 4rem;
  padding: 4rem;
}
.footer__head {
  margin: 0 0 13rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__logo {
  max-width: 20.5rem;
  max-height: 2.2rem;
  margin: 0 auto;
  display: block;
  transition: transform 0.3s;
}
body.pc .footer__logo:focus, body.pc .footer__logo:hover, body.mobile .footer__logo:active {
  transform: scale(1.1);
}
@media (min-width: 768px) {
  .footer__logo {
    margin: 0;
  }
}
.footer__wrap_fitst {
  margin: 0 0 6rem;
}
@media (min-width: 600px) {
  .footer__privacy_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
.footer__title {
  text-align: center;
  margin: 0 0 4rem;
}
@media (min-width: 768px) {
  .footer__title {
    font-size: 10rem;
  }
}
@media (min-width: 600px) {
  .footer__contact {
    justify-content: center;
    display: flex;
  }
}

.contact__adress, .contact__phone {
  font-size: 1.6rem;
  line-height: 2.56rem;
  color: rgb(255, 255, 255);
  text-align: center;
  text-decoration: none;
}
@media (min-width: 600px) {
  .contact__adress, .contact__phone {
    text-align: left;
  }
}
.contact__adress {
  font-family: "Montserrat-Bold", serif;
  margin: 0 0 4rem;
  display: block;
  transition: transform 0.3s;
}
body.pc .contact__adress:focus, body.pc .contact__adress:hover, body.mobile .contact__adress:active {
  transform: scale(1.1);
}
@media (min-width: 600px) {
  .contact__adress {
    margin: 0 4rem 0 0;
  }
}
.contact__phone {
  font-family: "Montserrat-ExtraBold", serif;
  display: block;
  transition: transform 0.3s;
}
body.pc .contact__phone:focus, body.pc .contact__phone:hover, body.mobile .contact__phone:active {
  transform: scale(1.1);
}
.contact__phone span {
  font-family: "Montserrat-Regular", serif;
}

.privacy_footer__wrap {
  margin: 0 0 3.2rem;
}
@media (min-width: 600px) {
  .privacy_footer__wrap {
    margin: 0;
  }
}
.privacy_footer__wrap ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 4rem;
  row-gap: 2rem;
}
.privacy_footer__wrap ul li a {
  font-size: 1.4rem;
  line-height: 2.24rem;
  color: rgb(233, 222, 255);
  text-decoration: none;
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s;
}
.privacy_footer__wrap ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 0.2rem;
  background-color: rgb(255, 255, 255);
  transition: left 0.3s;
}
body.pc .privacy_footer__wrap ul li a:focus, body.mobile .privacy_footer__wrap ul li a:active {
  transform: scale(1.1);
}
body.pc .privacy_footer__wrap ul li a:focus::after, body.mobile .privacy_footer__wrap ul li a:active::after {
  left: 0;
}
body.pc .privacy_footer__wrap ul li a:hover::after {
  left: 0;
}
.privacy_footer__text {
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.24rem;
  color: rgb(233, 222, 255);
  opacity: 0.5;
}
@media (min-width: 600px) {
  .privacy_footer__text {
    text-align: left;
  }
}

.header {
  position: fixed;
  top: 2rem;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 2rem;
  transition: background-color 0.3s, top 0.3s;
}
.header.backg {
  top: 0;
  background-color: rgb(11, 0, 26);
}
.header__contact_btn {
  display: none;
}
@media (min-width: 768px) {
  .header__contact_btn {
    display: flex;
  }
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 968px) {
  .header__container {
    padding: 0 5.6rem 0;
  }
}
.header__logo {
  max-width: 14.9rem;
  max-height: 1.6rem;
  margin: 0 2rem 0 0;
}
.header__logo a {
  display: block;
  transition: transform 0.3s;
}
body.pc .header__logo a:focus, body.pc .header__logo a:hover, body.mobile .header__logo a:active {
  transform: scale(1.1);
}
@media (min-width: 1024px) {
  .header__logo {
    max-width: 20.5rem;
    max-height: 2.2rem;
  }
}

.header__nav {
  position: relative;
  display: none;
}
@media (min-width: 768px) {
  .header__nav {
    display: flex;
    align-items: center;
  }
}
.header__nav a {
  text-transform: uppercase;
  color: rgb(233, 222, 255);
  font-size: 1.4rem;
  transition: opacity 0.3s;
  line-height: 1.4rem;
  text-decoration: none;
  font-family: "Montserrat-Medium", serif;
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s;
}
.header__nav a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 0.2rem;
  background-color: rgb(255, 255, 255);
  transition: left 0.3s;
}
body.pc .header__nav a:focus, body.mobile .header__nav a:active {
  transform: scale(1.1);
}
body.pc .header__nav a:focus::after, body.mobile .header__nav a:active::after {
  left: 0;
}
body.pc .header__nav a:hover::after {
  left: 0;
}
.header__nav ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 3.8rem;
  row-gap: 1rem;
  margin: 0 2rem 0 0;
}

.nav__cloud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: inherit;
  z-index: -1;
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgb(30, 0, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
}
.mobile-nav li a {
  text-transform: uppercase;
  color: rgb(233, 222, 255);
  font-size: 1.4rem;
  transition: opacity 0.3s;
  line-height: 1.4rem;
  text-decoration: none;
  font-family: "Montserrat-Medium", serif;
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s;
}
.mobile-nav li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 0.2rem;
  background-color: rgb(255, 255, 255);
  transition: left 0.3s;
}
body.pc .mobile-nav li a:focus, body.mobile .mobile-nav li a:active {
  transform: scale(1.1);
}
body.pc .mobile-nav li a:focus::after, body.mobile .mobile-nav li a:active::after {
  left: 0;
}
body.pc .mobile-nav li a:hover::after {
  left: 0;
}

.mobile-nav--open {
  right: 0;
}

.mobile-nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
}
.mobile-nav__list .active {
  opacity: 0.5;
}

.mobile-nav-btn {
  --time: 0.1s;
  --width: 4rem;
  --height: 3rem;
  --line-height: .4rem;
  --spacing: .6rem;
  --color: #ffffff;
  --radius: .4rem;
  height: 4.3rem;
  width: 4.3rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
}
@media (min-width: 768px) {
  .mobile-nav-btn {
    display: none !important;
  }
}

.nav-icon {
  position: relative;
  width: var(--width);
  height: var(--line-height);
  background-color: var(--color);
  border-radius: var(--radius);
}

.nav-icon::before,
.nav-icon::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: var(--width);
  height: var(--line-height);
  border-radius: var(--radius);
  background-color: var(--color);
  transition: transform var(--time) ease-in, top var(--time) linear var(--time);
}

.nav-icon::before {
  top: calc(-1 * (var(--line-height) + var(--spacing)));
}

.nav-icon::after {
  top: calc(var(--line-height) + var(--spacing));
}

.nav-icon.nav-icon--active {
  background-color: transparent;
}

.nav-icon.nav-icon--active::before,
.nav-icon.nav-icon--active::after {
  top: 0;
  transition: top var(--time) linear, transform var(--time) ease-in var(--time);
}

.nav-icon.nav-icon--active::before {
  transform: rotate(45deg);
}

.nav-icon.nav-icon--active::after {
  transform: rotate(-45deg);
}

.mobile-nav-btn {
  z-index: 999;
}

/* Index.html */
.advantages {
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .advantages__wrap {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 968px) {
  .advantages__title {
    width: 30%;
  }
}
.advantages__container {
  background-color: rgb(233, 222, 255);
  color: rgb(11, 0, 26);
  border-radius: 4rem;
  padding: 6rem 2rem;
}
@media (min-width: 968px) {
  .advantages__container {
    padding: 6rem 4rem;
  }
}
.advantages__wrap_text {
  margin: 0 0 4rem;
}
@media (min-width: 768px) {
  .advantages__wrap_text {
    width: 50%;
    margin: 0 2rem 0 0;
    max-width: 40.2rem;
  }
}
.advantages__text {
  margin: 0 0 3.2rem;
}
.advantages__list {
  border-top: 1px solid rgba(23, 0, 52, 0.4);
  max-width: 71.5rem;
}
@media (min-width: 768px) {
  .advantages__list {
    width: 50%;
  }
}
@media (min-width: 968px) {
  .advantages__list {
    width: 100%;
  }
}
.advantages__list li {
  padding: 2rem 0;
  border-bottom: 1px solid rgba(23, 0, 52, 0.4);
}
.advantages__list li span {
  font-size: 2.2rem;
  line-height: 3.52rem;
  font-family: "Montserrat-Medium", serif;
}
.advantages__list li p {
  text-transform: uppercase;
}

.main {
  padding: 2rem 0;
}
.main__container {
  background-color: rgb(30, 0, 68);
  padding: 13.7rem 2rem 4rem;
  border-radius: 2rem;
}
@media (min-width: 768px) {
  .main__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 968px) {
  .main__container {
    padding: 11.8rem 4rem 4rem;
  }
}
@media (min-width: 768px) {
  .main__wrap_text {
    width: 50%;
    max-width: 54.1rem;
    margin: 0 2rem 0 0;
  }
}
.main__title {
  font-size: 4rem;
  font-family: "Montserrat-Medium", serif;
  line-height: 100%;
  letter-spacing: -5%;
  margin: 0 0 2.4rem;
}
@media (min-width: 968px) {
  .main__title {
    font-size: 8rem;
  }
}
.main__text {
  font-size: 1.6rem;
  line-height: 1.92rem;
  letter-spacing: -0.09rem;
  margin: 0 0 6rem;
}
@media (min-width: 768px) {
  .main__text {
    margin: 0;
  }
}
@media (min-width: 968px) {
  .main__text {
    font-size: 2.4rem;
    line-height: 2.88rem;
  }
}
.main__img {
  max-width: 57.9rem;
  max-height: 55.2rem;
  margin: 0 auto;
  border-radius: 2rem;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main__img {
    width: 50%;
    margin: 0;
  }
}

.mission {
  padding: 2rem 0;
}
@media (min-width: 768px) {
  .mission__wrap {
    display: flex;
    justify-content: space-between;
  }
}
.mission__container {
  background-color: rgb(30, 0, 68);
  border-radius: 4rem;
  padding: 4rem 2rem;
}
@media (min-width: 968px) {
  .mission__container {
    padding: 4rem;
  }
}
.mission__wrap_text {
  margin: 0 0 4rem;
}
@media (min-width: 768px) {
  .mission__wrap_text {
    width: 50%;
    margin: 0 2rem 0 0;
    max-width: 40.2rem;
  }
}
.mission__title {
  margin: 0 0 8rem;
}
@media (min-width: 968px) {
  .mission__title {
    margin: 0 0 23rem;
  }
}
.mission__text {
  margin: 0 0 3.2rem;
}
.mission__img {
  border-radius: 3rem;
  overflow: hidden;
  max-width: 42rem;
  max-height: 52rem;
}
@media (min-width: 768px) {
  .mission__img {
    width: 50%;
  }
}
@media (min-width: 968px) {
  .mission__img {
    width: 60%;
  }
}

.packages {
  padding: 2rem 0;
}
.packages__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.4rem;
}
.packages__block_pack {
  width: 100%;
  max-width: 37rem;
  background: linear-gradient(93.26deg, #8338EC 10.4%, #9C2EF5 101.06%);
  border-radius: 2rem;
  padding: 3.6rem 3.5rem;
}
@media (min-width: 968px) {
  .packages__block_pack {
    display: flex;
    flex-direction: column;
    min-height: 36.9rem;
  }
}

.block_pack__title {
  font-size: 3.2rem;
  margin: 0 0 9.7rem;
  line-height: 3.9rem;
}
@media (min-width: 968px) {
  .block_pack__title {
    flex: 1 1 auto;
  }
}
.block_pack__title.marg {
  margin: 0 0 6rem;
}
@media (min-width: 968px) {
  .block_pack__title.marg {
    margin: 0;
  }
}
.block_pack__list li {
  font-size: 1.4rem;
  line-height: 2.24rem;
  display: flex;
}
.block_pack__list li::before {
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  display: block;
  margin: 1rem 1rem 0 0.9rem;
  width: 0.2rem;
  height: 0.2rem;
  background-color: rgb(255, 255, 255);
}

.intermediate {
  background: rgb(158, 45, 246);
  background: linear-gradient(93deg, rgb(158, 45, 246) 0%, rgb(185, 35, 255) 100%);
}

.story {
  padding: 4rem 0 2rem;
}
@media (min-width: 768px) {
  .story__wrap {
    display: flex;
    justify-content: space-between;
  }
}
.story__container {
  background-color: rgb(233, 222, 255);
  color: rgb(11, 0, 26);
  border-radius: 4rem;
  padding: 4rem 2rem;
}
@media (min-width: 968px) {
  .story__container {
    padding: 4rem;
  }
}
.story__wrap_text {
  margin: 0 0 4rem;
}
@media (min-width: 768px) {
  .story__wrap_text {
    width: 50%;
    margin: 0 2rem 0 0;
    max-width: 40.2rem;
  }
}
.story__title {
  text-transform: uppercase;
  margin: 0 0 8rem;
}
@media (min-width: 968px) {
  .story__title {
    margin: 0 0 23rem;
  }
}
.story__text {
  margin: 0 0 3.2rem;
}
.story__img {
  border-radius: 3rem;
  overflow: hidden;
  max-width: 66rem;
  max-height: 52rem;
}
@media (min-width: 768px) {
  .story__img {
    width: 50%;
  }
}
@media (min-width: 968px) {
  .story__img {
    width: 60%;
  }
}

/* Privacy.html */
.wrapper_privacy {
  background-color: rgb(246, 246, 246);
}

.privacy {
  padding: 0 0 6rem;
}
.privacy .container_title {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 4rem;
}
.privacy__title {
  padding: 13.7rem 0 6rem;
  background-color: rgb(30, 0, 68);
  border-radius: 0px 0px 2rem 2rem;
  margin: 0 0 6rem;
}
@media (min-width: 968px) {
  .privacy__title {
    padding: 18.8rem 0 4rem;
  }
}
.privacy__title h2 {
  font-size: 4rem;
  letter-spacing: 0.09rem;
  line-height: 100%;
  font-family: "Montserrat-Medium", serif;
}
@media (min-width: 1024px) {
  .privacy__title h2 {
    font-size: 8rem;
  }
}
.privacy__text {
  text-align: left;
  color: rgb(125, 125, 125);
  margin: 0 0 4rem;
  font-size: 1.6rem;
  line-height: 1.95rem;
}

.list__title {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 1.6rem;
  color: rgb(0, 0, 0);
  font-family: "Montserrat-Bold", serif;
}
.list__block {
  margin: 0 0 4rem;
}
.list__block:last-child {
  margin: 0;
}
.list__block p {
  text-align: left;
  color: rgb(125, 125, 125);
  font-size: 1.6rem;
  line-height: 1.95rem;
}
.list__block ul li {
  display: flex;
  text-align: left;
  color: rgb(125, 125, 125);
  font-size: 1.8rem;
  line-height: 2.3rem;
}
.list__block ul li::before {
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  display: block;
  margin: 1rem 1rem 0 0.9rem;
  width: 0.2rem;
  height: 0.2rem;
  background-color: rgb(125, 125, 125);
}

/* Conditions.html */
.wrapper_privacy {
  background-color: rgb(246, 246, 246);
}

.conditions {
  padding: 0 0 6rem;
}
.conditions .container_title {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 4rem;
}
.conditions__title {
  padding: 13.7rem 0 6rem;
  background-color: rgb(30, 0, 68);
  border-radius: 0px 0px 2rem 2rem;
  margin: 0 0 6rem;
}
@media (min-width: 968px) {
  .conditions__title {
    padding: 18.8rem 0 4rem;
  }
}
.conditions__title h2 {
  font-size: 4rem;
  letter-spacing: 0.09rem;
  line-height: 100%;
  font-family: "Montserrat-Medium", serif;
}
@media (min-width: 1024px) {
  .conditions__title h2 {
    font-size: 8rem;
  }
}
.conditions__text {
  text-align: left;
  color: rgb(125, 125, 125);
  margin: 0 0 4rem;
  font-size: 1.6rem;
  line-height: 1.95rem;
}

.list__title {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 1.6rem;
  color: rgb(0, 0, 0);
  font-family: "Montserrat-Bold", serif;
}
.list__block {
  margin: 0 0 4rem;
}
.list__block:last-child {
  margin: 0;
}
.list__block p {
  text-align: left;
  color: rgb(125, 125, 125);
  font-size: 1.6rem;
  line-height: 1.95rem;
}
.list__block ul li {
  display: flex;
  text-align: left;
  color: rgb(125, 125, 125);
  font-size: 1.8rem;
  line-height: 2.3rem;
}
.list__block ul li::before {
  content: "";
  flex: 0 0 auto;
  border-radius: 50%;
  display: block;
  margin: 1rem 1rem 0 0.9rem;
  width: 0.2rem;
  height: 0.2rem;
  background-color: rgb(125, 125, 125);
}