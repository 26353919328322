.mission {
  padding: 2rem 0;
  &__wrap{
    @include _768{
      display: flex;
      justify-content: space-between;
    }
  }
  &__container {
    background-color: rgba(30, 0, 68, 1);
    border-radius: 4rem;
    padding: 4rem 2rem;
    @include _968{
      padding: 4rem;
    }
  }

  &__wrap_text {
    margin: 0 0 4rem;
    @include _768{
      width: 50%;
      margin: 0 2rem 0 0;
      max-width: 40.2rem;
    }
  }

  &__title {
    margin: 0 0 8rem;
    @include _968{
      margin: 0 0 23rem;
    }
  }

  &__text {
    margin: 0 0 3.2rem;
  }

  &__img {
    border-radius: 3rem;
    overflow: hidden;
    max-width: 42rem;
    max-height: 52rem;
    @include _768{
      width: 50%;
    }
    @include _968{
      width: 60%;
    }
  }
}