@font-face {
	font-family: 'Montserrat-Bold';
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
			url('../../fonts/Montserrat-Bold.woff2') format('woff2'),
			url('../../fonts/Montserrat-Bold.woff') format('woff'),
			url('../../fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-ExtraBold';
	src: local('Montserrat Extra Bold'), local('Montserrat-Extra-Bold'),
			url('../../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
			url('../../fonts/Montserrat-ExtraBold.woff') format('woff'),
			url('../../fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Medium';
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
			url('../../fonts/Montserrat-Medium.woff2') format('woff2'),
			url('../../fonts/Montserrat-Medium.woff') format('woff'),
			url('../../fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-Regular';
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
			url('../../fonts/Montserrat-Regular.woff2') format('woff2'),
			url('../../fonts/Montserrat-Regular.woff') format('woff'),
			url('../../fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}