.advantages {
  padding: 2rem 0;
  &__wrap{
    @include _768{
      display: flex;
      justify-content: space-between;
    }
  }
  &__title{
    @include _968{
      width: 30%;
    }
  }
  &__container {
    background-color: rgba(233, 222, 255, 1);
    color: rgba(11, 0, 26, 1);
    border-radius: 4rem;
    padding: 6rem 2rem;
    @include _968{
      padding: 6rem 4rem;
    }
  }

  &__wrap_text {
    margin: 0 0 4rem;
    @include _768{
      width: 50%;
      margin: 0 2rem 0 0;
      max-width: 40.2rem;
    }
  }

  &__text {
    margin: 0 0 3.2rem;
  }

  &__list {
    border-top: 1px solid rgba(23, 0, 52, 0.4);
    max-width: 71.5rem;
    @include _768{
      width: 50%;
    }
    @include _968{
      width: 100%;
    }

    li{
      padding: 2rem 0;
      border-bottom: 1px solid rgba(23, 0, 52, 0.4);
      span{
        font-size: 2.2rem;
        line-height: 3.52rem;
        font-family: $montserrat-medium;
      }
      p{
        text-transform: uppercase;
      }
    }
  }
}