.packages {
  padding: 2rem 0;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.4rem;
  }

  &__block_pack {
    width: 100%;
    max-width: 37rem;
    background: linear-gradient(93.26deg, #8338EC 10.4%, #9C2EF5 101.06%);
    border-radius: 2rem;
    padding: 3.6rem 3.5rem;

    @include _968{
      display: flex;
      flex-direction: column;
      min-height: 36.9rem;
    }
  }
}

.block_pack {

  &__title {
    font-size: 3.2rem;
    margin: 0 0 9.7rem;
    line-height: 3.9rem;
    @include _968{
      flex: 1 1 auto;
    }

    &.marg{
      margin: 0 0 6rem;
      @include _968{
        margin: 0;
      }
    }
  }

  &__list {
    li{
      font-size: 1.4rem;
      line-height: 2.24rem;
      display: flex;
      &::before{
        content: '';
        flex: 0 0 auto;
        border-radius: 50%;
        display: block;
        margin: 1rem 1rem 0 .9rem;
        width: .2rem;
        height: .2rem;
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.intermediate {
  background: rgb(158,45,246);
  background: linear-gradient(93deg, rgba(158,45,246,1) 0%, rgba(185,35,255,1) 100%);
}
