@mixin _1200{
  @media (min-width: $pc){
      @content;
  }
}
@mixin _1140{
  @media (min-width: $pc-sm){
      @content;
  }
}
@mixin _1024{
  @media (min-width: $lg-tab){
      @content;
  }
}
@mixin _968{
  @media (min-width: $tab){
      @content;
  }
}
@mixin _900{
  @media (min-width: $tab-sm){
      @content;
  }
}
@mixin _768{
  @media (min-width: $sm-tab){
      @content;
  }
}
@mixin _600{
  @media (min-width: $lg-mob){
      @content;
  }
}
@mixin _480{
  @media (min-width: $mob){
      @content;
  }
}
@mixin _375{
  @media (min-width: $sm-mob){
      @content;
  }
}

@mixin hover-underline{
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform .3s;
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: .2rem;
    background-color: rgba(255, 255, 255, 1);
    transition: left .3s;
  }

  body.pc &:focus,
  body.mobile &:active{
    transform: scale(1.1);
    &::after{
      left: 0;
    }
  }
  body.pc &:hover{
    &::after{
      left: 0;
    }
  }
  
}

@mixin hover-underline-footer{
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform .3s;
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: .2rem;
    background-color: rgb(210, 29, 5);
    transition: left .3s;
  }
  body.pc &:focus,
  body.mobile &:active{
    transform: scale(1.1);
    &::after{
      left: 0;
    }
  }
  body.pc &:hover{
    &::after{
      left: 0;
    }
  }
  
}

@mixin scale{
  display: block;
  transition: transform .3s;
  body.pc &:focus,
  body.pc &:hover,
  body.mobile &:active{
    transform: scale(1.1);
  }
}

@mixin button-scale{
  display: block;
  transition: transform .3s, opacity .3s;
  cursor: pointer;
  body.pc &:focus,
  body.pc &:hover,
  body.mobile &:active{
    transform: scale(1.1);
  }

  body.pc &:focus{
    border: .2rem solid black;
  }

  &:active{
    opacity: .5;
  }
}

@mixin input-scale{
  display: block;
  transition: transform .3s, opacity .3s;
  cursor: pointer;
  body.pc &:focus,
  body.pc &:hover,
  body.mobile &:active{
    transform: scale(1.05);
  }
  body.pc &:focus{
    border: .2rem solid;
    cursor: auto;
  }
}