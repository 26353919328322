.main {
  padding: 2rem 0;

  &__container {
    background-color: rgba(30, 0, 68, 1);
    padding: 13.7rem 2rem 4rem;
    border-radius: 2rem;
    @include _768{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @include _968{
      padding: 11.8rem 4rem 4rem;
    }
  }

  &__wrap_text {
    @include _768{
      width: 50%;
      max-width: 54.1rem;
      margin: 0 2rem 0 0;
    }
  }

  &__title {
    font-size: 4rem;
    font-family: $montserrat-medium;
    line-height: 100%;
    letter-spacing: -5%;
    margin: 0 0 2.4rem;
    @include _968{
      font-size: 8rem;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.92rem;
    letter-spacing: -0.09rem;
    margin: 0 0 6rem;
    @include _768{
      margin: 0;
    }
    @include _968{
      font-size: 2.4rem;
      line-height: 2.88rem;
    }
  }

  &__img {
    max-width: 57.9rem;
    max-height: 55.2rem;
    margin: 0 auto;
    border-radius: 2rem;
    overflow: hidden;
    @include _768{
      width: 50%;
      margin: 0;
    }
  }
}